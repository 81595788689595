import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// const widget = ReactDOM.createRoot(document.querySelector('#bc-widget'));
// console.log(widget);

const rootElement = document.getElementById('bc-widget');
const widget = ReactDOM.createRoot(rootElement);

const venue = rootElement.dataset.venue ? rootElement.dataset.venue : 'none';
const hideVenue = rootElement.dataset.hidevenue === 'true' ? true : false;
// const grid = rootElement.dataset.grid === 'true' ? true : false;
const recommended = rootElement.dataset.recommended === 'true' ? true : false;
const column = rootElement.dataset.column === 'true' ? true : false;
const limit = rootElement.dataset.limit ? rootElement.dataset.limit : '99';
const key = rootElement.dataset.key ? rootElement.dataset.key : 'none';

const setMaxLImit = (limit) => {
	return limit * 1 > 100 ? 100 : limit;
};
//  boolean
const hideshadow = rootElement.dataset.hideshadow === 'true' ? true : false;
const hidebounce = rootElement.dataset.hidebounce === 'true' ? true : false;
const hideborder = rootElement.dataset.hideborder === 'true' ? true : false;
const cornerborder = rootElement.dataset.cornerborder === 'true' ? true : false;
const dailycal = rootElement.dataset.dailycal === 'true' ? true : false;
const showfilters = rootElement.dataset.showfilters === 'true' ? true : false;

// hex
const accentcolor = rootElement.dataset.accentcolor
	? rootElement.dataset.accentcolor
	: '';
const bordercolor = rootElement.dataset.bordercolor
	? rootElement.dataset.bordercolor
	: '';
const bodybkg = rootElement.dataset.bodybkg ? rootElement.dataset.bodybkg : '';
const region = rootElement.dataset.region === 'true' ? true : false;

//

const config = {
	venue: venue,
	hideVenue: hideVenue,
	// grid: grid,
	recommended: recommended,
	key: key,
	accentColor: accentcolor,
	column: column,
	limit: setMaxLImit(limit),
	hideShadow: hideshadow,
	hideBounce: hidebounce,
	hideBorder: hideborder,
	cornerBorder: cornerborder,
	borderColor: bordercolor,
	bodyBkg: bodybkg,
	dailyCal: dailycal,
	showFilters: showfilters,
	region: region,
};

// const venue = rootElement.dataset.venue ? rootElement.dataset.venue : 'none',
// const hideVenue =  dataSet.hideVenue === 'true' ? true : false,

widget.render(
	<React.StrictMode>
		<App venue={venue} hideVenue={hideVenue} config={config} />
	</React.StrictMode>
);

// // Find all widget divs
// const widgetDivs = document.querySelectorAll('.nicoraynaud-finance-widget');
// console.log(widgetDivs);

// // Inject our React App into each class
// widgetDivs.forEach((div) => {
// 	let widget = ReactDOM.createRoot(div);
// 	widget.render(
// 		<React.StrictMode>
// 			<App symbol={div.dataset.symbol} />
// 		</React.StrictMode>,
// 		div
// 	);
// });
