import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FestivalsInfo from './FestivalsInfo';
export default function FestivalsInfoModal({ open, handleCloseInfoModal }) {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-10'
				onClose={handleCloseInfoModal}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-100'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-100'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-zinc-200/70 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 overflow-y-auto '>
					<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-100'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-100'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<Dialog.Panel className='relative transform overflow-hidden  border bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
								<div id='bcfw-info-wrapper'>
									<div className='flex  flex-row justify-end '>
										<a
											onClick={() => {
												handleCloseInfoModal(false);
											}}
										>
											<div className=' flex text-2xl pb-4 items-center justify-center  bg-white cursor-pointer '>
												X{' '}
											</div>
										</a>
									</div>
									<div className='  '>
										<Dialog.Title
											as='h3'
											className='text-lg font-medium leading-6 text-gray-900'
										></Dialog.Title>
										<FestivalsInfo></FestivalsInfo>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
