import React from 'react';

export default function FestivalsSelectVenue({
	selectedVenue,
	uniqueVenues,
	filterByVenue,
}) {
	return (
		<>
			<label htmlFor='bcfw-settings-venues-select' className='sr-only'>
				Select Venue
			</label>
			<select
				id='bcfw-settings-venues-select'
				value={selectedVenue}
				onChange={(e) => filterByVenue(e.target.value)}
				// className='w-full sm:w-auto'
				className='w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
			>
				<option value=''>All Venues</option>
				{uniqueVenues.map((venue, index) => (
					<option key={index} value={venue}>
						{venue}
					</option>
				))}
			</select>
		</>
	);
}
