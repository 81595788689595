import React from 'react';

// Import appropriate web icons library, e.g., react-icons
import {
	FaSpotify,
	FaInstagram,
	FaFacebookSquare,
	FaQuestion,
} from 'react-icons/fa';

const getIconComponent = (type) => {
	switch (type) {
		case 'spotify':
			return FaSpotify;
		case 'instagram':
			return FaInstagram;
		case 'facebook':
			return FaFacebookSquare;
		default:
			return FaQuestion; // A default icon in case of unexpected values
	}
};

const SocialLinkIcon = ({ type, url }) => {
	const IconComponent = getIconComponent(type);
	return (
		<a
			href={url}
			target='_blank'
			rel='noopener noreferrer'
			style={{ padding: 4 }}
			aria-label={`Link to ${type}`}
			id={`bcfw-social-links-${type}`}
		>
			<IconComponent size={24} color='black' />
		</a>
	);
};

export default function FestivalsSocialLinks({ socialLinks }) {
	return (
		<div
			className='flex flex-row justify-start space-x-2'
			id='bcfw-social-links-wrapper'
		>
			{socialLinks &&
				socialLinks.map((link, index) => (
					<div key={index} className=''>
						<SocialLinkIcon type={link.type} url={link.url} />
					</div>
				))}
		</div>
	);
}
