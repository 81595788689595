import React from 'react';

import FestivalsLabelTime from './FestivalsLabelTime';
import FestivalsLabelVenue from './FestivalsLabelVenue';
import FestivalsStar from './FestivalsStar';
import ItemDescription from '../ItemDescription';
import FestivalsOptimisedImage from './FestivalsOptimisedImage';

export default function FestivalsScheduleItem({
	item,
	handleOpenDetailsModal,
	highlightStyle,
	config,
}) {
	const theAccentColor = { color: config.accentColor };
	const theBkgColor = { backgroundColor: config.bodyBkg };

	return (
		<>
			<div
				className='flex  flex-row  justify-between   md:items-center   pr-4 transition-all hover:md:scale-101'
				style={Object.assign(theBkgColor)}
			>
				{/* Title and tags */}
				<div className='bcfw-schedule-row-wrapper md:items-start  py-0 sm:py-0 w-full  '>
					{/* <a
												onClick={() => {
													handleOpenDetailsModal(
														item
													);
												}}
											> */}
					<div
						className='flex w-full cursor-pointer flex-row items-center '
						onClick={() => handleOpenDetailsModal(item)}
						role='button'
					>
						{/* <div className='flex cursor-pointer flex-row md:w-[50%]'> */}

						{/* Time */}
						<div className='bcfw-time-wrapper p-1 pr-2 md:p-4 text-xs md:text-base font-bold flex flex-col items-center'>
							{/* <div className=''> */}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth='1'
								stroke='currentColor'
								className='bcfw-time-wrapper w-6 h-6'
								aria-hidden='true'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
							{/* </div> */}
							<FestivalsLabelTime
								item={item}
							></FestivalsLabelTime>
						</div>

						{/* Image */}
						{item.imageUrl && (
							<>
								<FestivalsOptimisedImage
									key={item.imageUrl}
									imageUrl={item.imageUrl}
									altText={`Cover graphic for ${item.name}`}
									transformationOptions={[
										{ height: '160', width: '160' },
									]}
									lqipOptions={{ active: true, quality: 20 }}
									width='160' // Specify width
									height='160' // Specify height
									className='h-16 w-16  object-cover md:h-20 md:w-20'
								></FestivalsOptimisedImage>
							</>
						)}

						{/* <img
							src={`${
								item.imageUrl
									? item.imageUrl + '/tr:w-100,h-100,c-fill'
									: 'https://widget.broadcast.events/v1/musikkfest/mf-default-logo.png'
							}`}
							alt={`Cover graphic for ${item.name}}`}
							// className='h-8 w-8 rounded-lg object-cover md:h-12 md:w-12'
							className={`h-16 w-16  object-cover md:h-20 md:w-20 ${
								!item.imageUrl ? '' : '' // Apply opacity class when no image URL
							}`}
						/> */}

						{/* Name */}
						<div className='flex flex-col justify-center  pl-4'>
							<div className=' text-sm md:text-lg font-bold flex items-start'>
								<style>{highlightStyle}</style>
								<div
									// key={index}
									dangerouslySetInnerHTML={{
										__html: `${
											item.highlightedName || item.name
										} `,
									}}
								/>
								{/* {item.name} */}
							</div>

							{/* Mobile - show the venue */}
							<div className=' items-top flex flex-row md:hidden '>
								<div className=' items-center justify-items-center   md:p-4 text-xs md:text-base'>
									<FestivalsLabelVenue
										item={item}
										highlightedExternalVenueName={
											item.highlightedExternalVenueName
										}
										highlightedRelatedEventVenueName={
											item.highlightedRelatedEventVenueName
										}
									></FestivalsLabelVenue>
								</div>
							</div>

							{/* <div className=''>
															{item.description}
														</div> */}

							{/* Show tags */}
							<div className=' text-md '>
								{item.highlightedTags ? (
									<>
										<div className='flex flex-row items-center pt-1'>
											{item.highlightedTags.map(
												(tagObj, index) => (
													<div
														key={index}
														className='mr-2 rounded-md border border-zinc-500 px-2 text-xs line-clamp-1 md:text-sm'
														dangerouslySetInnerHTML={{
															__html: tagObj.highlightedTag,
														}}
													/>
												)
											)}
										</div>
									</>
								) : (
									<>
										{item.tags && (
											<>
												<div className='flex flex-row items-center pt-1'>
													{item.tags.map(
														(tag, index) => (
															<div
																className='mr-2 rounded-sm border  border-zinc-400 px-3 text-xs line-clamp-1 md:text-sm'
																key={index}
															>
																<div
																	dangerouslySetInnerHTML={{
																		__html: `${
																			item.highlightedTags ||
																			tag
																		} `,
																	}}
																/>
															</div>
														)
													)}
												</div>
											</>
										)}
									</>
								)}
							</div>

							{/* <div className='bg-red-200'>
								<ItemDescription
									description={item}
									item={item}
									handleOpenDetailsModal={
										handleOpenDetailsModal
									}
								></ItemDescription>
							</div> */}
						</div>
					</div>

					{/* </a> */}
				</div>

				{/* Venue and star on desktop  */}
				<div className=' hidden flex-row items-center md:flex min-w-fit'>
					<div className=' items-center justify-items-center  p-1 md:p-2 text-base '>
						<FestivalsLabelVenue
							item={item}
							highlightedExternalVenueName={
								item.highlightedExternalVenueName
							}
							highlightedRelatedEventVenueName={
								item.highlightedRelatedEventVenueName
							}
						></FestivalsLabelVenue>
					</div>

					<div className='pl-4'>
						<FestivalsStar
							event={item}
							disabled={false}
						></FestivalsStar>
					</div>
				</div>

				{/* Star on mobile */}
				<div className='flex items-start md:hidden '>
					<div className='pl-4 pt-2 '>
						<FestivalsStar
							event={item}
							disabled={false}
						></FestivalsStar>
					</div>
				</div>
			</div>

			{/* <div className=''>
				<ItemDescription
					description={item}
					item={item}
					handleOpenDetailsModal={handleOpenDetailsModal}
				></ItemDescription>
			</div> */}
		</>
	);
}
