import React, { useEffect, useState } from 'react';
import LabelTime from './FestivalsLabelTime';
import LabelVenue from './FestivalsLabelVenue';
import FestivalsOptimisedImage from './FestivalsOptimisedImage';
import FestivalsSocialLinks from './FestivalsSocialLinks';
// import Star from '../atoms/Star';

export default function Details({
	itemToShow,
	isFavorite,
	toggleFavorite,
	setIsFavorite,
}) {
	const [scheduleItem, setScheduleItem] = useState(null);

	useEffect(() => {
		setScheduleItem(itemToShow);
	}, [itemToShow]);

	function linkify2(text) {
		// Regular expression to match URLs with and without protocol
		var urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;

		// Replace URLs with anchor tags
		return text.replace(urlRegex, function (url) {
			if (url.startsWith('http')) {
				return '<' + url + '>';
			} else {
				return '<http://' + url + '>';
			}
		});
	}

	// function parseContent(content) {
	// 	// Replace new lines and line breaks with <br> tags
	// 	const formattedContent = content.replace(/(?:\r\n|\r|\n)/g, '<br>');

	// 	// Replace URLs with <a> tags
	// 	const withLinks = formattedContent.replace(
	// 		/(https?:\/\/[^\s]+)/g,
	// 		'<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
	// 	);

	// 	return withLinks;
	// }

	function parseContent(content) {
		// Replace new lines and line breaks with <br> tags
		const formattedContent = content.replace(/(?:\r\n|\r|\n)/g, '<br>');

		// Replace Markdown links with <a> tags
		const withMarkdownLinks = formattedContent.replace(
			/\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
			'<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
		);

		// Replace plain URLs with <a> tags
		const withPlainLinks = withMarkdownLinks.replace(
			/(?<!href=")(https?:\/\/[^\s]+)/g,
			'<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
		);

		return withPlainLinks;
	}

	// console.log(scheduleItem && scheduleItem.description);
	return (
		<>
			{scheduleItem && (
				<>
					<div className='flex flex-col text-left '>
						<FestivalsOptimisedImage
							imageUrl={scheduleItem.imageUrl}
							altText={`Cover graphic for ${scheduleItem.name}}`}
							transformationOptions={[
								{ height: '600', width: '600' },
							]}
							lqipOptions={{ active: true, quality: 5 }}
							width='600' // Specify width
							height='600' // Specify height
							className='object-cover w-full h-full'
						></FestivalsOptimisedImage>
						{/* <img
							src={`${
								scheduleItem.imageUrl
									? scheduleItem.imageUrl + ''
									: 'https://widget.broadcast.events/v1/musikkfest/holding.png'
							}`}
							alt=''
							className='  group-hover:opacity-90'
						/> */}
						<div className='py-4'>
							<div className='flex flex-row items-center justify-between'>
								<div
									className='text-2xl font-bold '
									id='bc-widget-detail-name'
								>
									{scheduleItem.name}
								</div>
							</div>

							<div className='flex flex-row items-center justify-start'>
								<div
									id='bc-widget-detail-time'
									className='pr-4 font-bold text-base'
								>
									<LabelTime item={scheduleItem}></LabelTime>
								</div>
								<div
									className='text-base'
									id='bc-widget-detail-venue'
								>
									<LabelVenue
										item={scheduleItem}
									></LabelVenue>
								</div>
							</div>
						</div>
						{scheduleItem.tags && (
							<>
								<div className='flex flex-row items-center '>
									{scheduleItem.tags.map((tag, index) => (
										<div
											className='mr-2  border  border-zinc-400 px-3 text-xs line-clamp-1 md:text-base'
											key={index}
										>
											{tag}
										</div>
									))}
								</div>
							</>
						)}

						{/* <div
							className=''
							id='bc-widget-detail-description'
						>
							{parseContent(scheduleItem.description)}
						</div> */}

						<FestivalsSocialLinks
							socialLinks={scheduleItem.socialLinks}
						></FestivalsSocialLinks>

						<div
							className='py-2 text-base'
							dangerouslySetInnerHTML={{
								__html: parseContent(scheduleItem.description),
							}}
						></div>
					</div>
				</>
			)}
		</>
	);
}
