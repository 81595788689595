// export default function FestivalsOptimisedImage() {
//   return (
//     <div>FestivalsOptimisedImage</div>
//   )
// }
import React from 'react';
import { IKImage, IKContext } from 'imagekitio-react';

const FestivalsOptimisedImage = ({
	imageUrl,
	altText,
	width,
	height,
	transformationOptions,
	lqipOptions,
	className,
}) => {
	const urlEndpoint = imageUrl.substring(0, imageUrl.indexOf('/', 8)); // Extracting the base URL

	return (
		<IKContext urlEndpoint={urlEndpoint}>
			<IKImage
				src={imageUrl}
				alt={altText}
				width={width} // Set width
				height={height} // Set height
				transformation={transformationOptions}
				loading='lazy'
				lqip={lqipOptions}
				className={className} // Apply custom class
			/>
		</IKContext>
	);
};

export default FestivalsOptimisedImage;
