import React from 'react';
import moment from 'moment';

export default function FestivalsLabelTime({ item }) {
	const getDateFormatPiPFest = (startTime, endTime) => {
		var nowTime = moment().toISOString();
		var isHappeningNow = false;

		if (nowTime >= endTime) {
		} else {
			if (nowTime >= startTime) {
				isHappeningNow = true;
			} else {
			}
		}
		var valToReturn = 'Now!';

		if (isHappeningNow === true) {
			return valToReturn;
		} else {
			return moment(startTime).format('HH:mm');
		}
	};

	const getDateFormatPiPFestShort = (startTime, endTime) => {
		var nowTime = moment().toISOString();
		var isHappeningNow = false;

		if (nowTime >= endTime) {
		} else {
			if (nowTime >= startTime) {
				isHappeningNow = true;
			} else {
			}
		}
		var valToReturn = 'Now!';

		if (isHappeningNow === true) {
			return valToReturn;
		} else {
			return moment(startTime).format('ddd DD MMM @ TBC');
		}
	};

	const handleDateFormat = (start, end) => {
		const dateDisplayType = 0;
		if (dateDisplayType === 1) {
			return getDateFormatPiPFestShort(start, end);
		} else if (dateDisplayType === 2) {
			return 'Date TBC';
		} else {
			return getDateFormatPiPFest(start, end);
		}
	};

	return handleDateFormat(item.start_time_iso, item.end_time_iso);
}
