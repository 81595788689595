import React from 'react';
import FestivalsPoweredBy from './FestivalsPoweredBy';

export default function FestivalsInfo() {
	return (
		<>
			<div className='flex flex-col space-y-4 mf-p'>
				{/* <div className='text-2xl font-bold text-white'>
					Program 2023
				</div> */}
				<div className=''>
					<p className='text-zinc-900 py-2'>
						How to use our festival guide:
					</p>
					<p className='text-zinc-900 py-2'>
						Click <strong>A-Z</strong> to sort all artists in
						alphabetical order or <strong>Stage</strong> to see all
						stages in alphabetical order.{' '}
					</p>
					<p className='text-zinc-900 py-2'>
						<strong>Time</strong> will show you everything that´s
						going on sorted according to starting time.
					</p>
					<p className='text-zinc-900 py-2'>
						Looking for a particular artist or genre? Try{' '}
						<strong>search</strong>.
					</p>
					<p className='text-zinc-900 py-2'>
						Looking for a particular <strong>venue</strong>? Click
						the venues box and find the one you want.
					</p>

					<div className='border border-black p-2 mt-4 bg-zinc-100'>
						<FestivalsPoweredBy compact={false} />
					</div>
				</div>
			</div>
		</>
	);
}
