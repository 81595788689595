import moment from 'moment';

export function eventLogger(action, payload) {
	logTinyBird(action, payload);
}

const logTinyBird = (action, payload) => {
	// console.log(JSON.stringify(payload));
	const trackingObject = {
		timestamp: moment().format(),
		session_id: '12345',
		action: action,
		version: '1',
		payload: JSON.stringify(payload),
	};

	let token =
		'p.eyJ1IjogImUwYmI3MjZlLWMyNzctNDE3OS04YzQyLTZmZDc1ZGE1ZjE1NSIsICJpZCI6ICI1MWZlMWIzOS02MDUyLTQ2Y2EtODU3MS1hODlmZmQ5MjAyNGIifQ.Xsx-wRMqix9xKTysQDfcEKKX4uyfCSzRJJmZtzffEgI';
	let dataSource = 'analytics_events';
	let apiUrl = 'https://api.tinybird.co/v0/events';
	var url = apiUrl + '?name=' + dataSource + '&token=' + token;

	fetch(url, {
		method: 'POST',
		body: JSON.stringify(trackingObject),
	})
		.then(function (r) {
			let status = r ? r.status : 0;
			if (status === 200 || status === 202) {
			} else {
				// console.log('error');
			}
		})
		.catch(function (e) {
			// console.log('catch');
		});
};
