import React from 'react';

export default function FestivalsLabelVenue({
	item,
	highlightedExternalVenueName,
	highlightedRelatedEventVenueName,
}) {
	const theLabelVenue = item.externalVenueName
		? highlightedExternalVenueName || item.externalVenueName
		: highlightedRelatedEventVenueName || item.relatedEvent.venue.name;
	return (
		<div
			// key={index}
			dangerouslySetInnerHTML={{
				__html: `${theLabelVenue} `,
			}}
		/>
	);
}
