import LayoutWidget from './components/LayoutWidget';
import MusikkFestWidget from './components/festivalsWidget/MusikkFestWidget';

import Axios from 'axios';

import moment from 'moment';

import { useEffect, useState } from 'react';
import Spinner from './components/Spinner';

import { eventLogger } from './lib/eventLogger';
import Festivals from './components/festivalsWidget/Festivals';

const MUSIKKFEST = false;
const MUSIKKNYHETER = false;
const FESTIVALS = true; // Add this line

function App(props) {
	const [festivals, setFestivals] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const hostname = window.location.hostname;

	useEffect(() => {
		setIsLoading(true);
		MUSIKKFEST || (FESTIVALS && loadData());
		// console.log('first');
		MUSIKKFEST || (FESTIVALS && logMusikkFestView());
	}, []);

	const logMusikkFestView = (e) => {
		// console.log('logMusikkFestView');
		if (hostname === 'localhost') {
			// console.log('localhost');
		} else {
			eventLogger('festival_view', {
				eventName: 'Musikkfest',
				eventId: 'kSa0JG4F2w',
				page: 'PromoHome',
				venueId: '',
				venueName: '',
				hostname: hostname,
			});
		}
	};

	const loadData = async () => {
		const res = await Axios.get(
			`https://demo.broadcastapp.no/api/v1/musikkfest?key=F2ydnTBWLiFvvbbm33hBIM73EMYjIZf8ggv92ZE7uQ1nJuvzrkBXy2fs5iEw5rvc`
		);
		const body = res.data;
		setFestivals(body);
		setIsLoading(false);
	};

	return (
		<>
			{MUSIKKFEST ? (
				<>
					<div
						className='App p-4 bg-musikkfestLight'
						id='bc-mf-widget-wrapper'
					>
						{isLoading ? (
							<>
								<div className='flex min-h-screen items-center justify-center'>
									<main role='main'>
										<div className='text-center'>
											<Spinner></Spinner>
										</div>
									</main>
								</div>
							</>
						) : (
							<>
								<MusikkFestWidget
									festivals={festivals}
								></MusikkFestWidget>
							</>
						)}
					</div>
				</>
			) : FESTIVALS ? (
				<>
					<style
						dangerouslySetInnerHTML={{
							__html: `
               	#bc-widget-wrapper {
					background-color: #ffffff !important;
					padding: 10px !important;
				}
				#bc-widget-poweredby-header {
					// display: none !important;
				}

				#bc-festivals-wrapper {
					// border: 1px solid #e5e5e5 !important;
					// padding: 10px !important;
				}
				#bc-festivals-settings {
					background-color: #ffffff !important;
					// display: none !important;	
				}
				#bc-festivals-settings-sort-wrapper {}
				#bc-festivals-settings-sort-name {}
				#bc-festivals-settings-sort-stage {}
				#bc-festivals-settings-sort-time {}
				#bc-festivals-settings-sort-stared {}
				#bc-festivals-settings-filter-wrapper {}
				#bc-festivals-settings-filter-input {}
				#bc-festivals-settings-venues-wrapper {}
				#bc-festivals-settings-venues-select {}

				#bc-festivals-settings-finished-wrapper {
					// display: none !important;	
				}
				#bc-festivals-settings-finished-input {}
				#bc-festivals-settings-finished-label {}

				#bc-festivals-heading-venue {}
				#bc-festivals-schedule-wrapper {}
				
				#bc-festivals-schedule-row {}
				#bc-festivals-schedule-row-title {}
				#bc-festivals-schedule-row-item {
					// border: 10px solid #e5e5e5 !important;
					// padding: 10px !important;
					// display: none !important;

				}
				
            `,
						}}
					></style>
					<div id='bc-widget-wrapper' className='m-0 p-0'>
						<Festivals festivals={festivals}></Festivals>
					</div>
				</>
			) : (
				<>
					{MUSIKKNYHETER ? (
						<>
							<style
								dangerouslySetInnerHTML={{
									__html: `<style>
                #bc-widget-event-tags {
                    color: #ffffff !important;
                }
                #bc-widget-body-container {
                    text-align: left !important; 
                    background-color: #333333 !important
                }
                #bc-widget-event-venue, #bc-widget-event-date, #bc-widget-event-name {
                    font-family: 'Droid Serif', serif !important;
                    color: #ffffff !important;
                }
                #bc-widget-event-name {
                    text-transform: lowecase;
                    font-weight: bold !important;
                    font-size: 16px !important;
                }
                #bc-widget ul {
                    padding-inline-start: 0px !important;
                    margin-block-start: 0px !important;
                    margin-block-end: 0px !important;
                }
                #bc-widget-event-image {
                    border-width: 1px !important;
                    border: 1;
                }
            </style>`,
								}}
							></style>
						</>
					) : null}

					<div className='App p-4 mf-p' id='bc-widget-wrapper'>
						<LayoutWidget
							venue={props.venue}
							hideVenue={props.hideVenue}
							config={props.config}
						></LayoutWidget>
					</div>
				</>
			)}
		</>
	);
}
App.displayName = 'BCWidget';
export default App;
