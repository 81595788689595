import React, { useEffect, useState } from 'react';
// import { ArrowUpIcon } from '@heroicons/react/outline'; // Make sure you have '@heroicons/react' library installed

export default function FestivalsScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	// Show button when page is scrolled up to given distance
	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	// Set the top cordinate to 0
	// make scrolling smooth
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);
	}, []);

	return (
		<div className='fixed bottom-6 right-6'>
			{isVisible && (
				<button
					onClick={scrollToTop}
					className='bg-black text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
					id='bcfw-scroll-to-top-btn'
					aria-label='Scroll to top'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-6 h-6'
						aria-hidden='true'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18'
						/>
					</svg>
				</button>
			)}
		</div>
	);
}
