import React from 'react';

export default function FEstivalsPoweredBy({ compact }) {
	return (
		<>
			<div className='flex  flex-row items-center justify-end sm:justify-between '>
				{!compact && (
					<>
						<div className='text-xs sm:text-base pr-2'>
							{/* Festival program is powered by{' '}
					<span className='text-fuchsia-500 font-bold'>
						{' '}
						<a href='https://www.broadcast.events'>broadcast</a>.
					</span>{' '}
					Scan the code to get the broadcast app for iOS or Android. */}
							<div className=''>
								Festival program powered by{' '}
								<span className='text-fuchsia-500 font-bold'>
									<a href='https://www.broadcast.events'>
										broadcast
									</a>
								</span>
								, your free guide to concerts, clubs and
								festivals.
							</div>
							<div className='hidden sm:block'>
								Scan code for iOS/Android app.
							</div>
							<div className='sm:hidden'>
								Tap the icon to download for iOS and Android.
							</div>
						</div>
					</>
				)}

				<div className='hidden sm:block shrink-0'>
					<a
						href='https://qr.broadcast.events/g/3dWjooOAwS'
						aria-label='Link to broadcast.events'
					>
						<img
							src='https://widget.broadcast.events/v1/musikkfest/QR_MusikkFestWidget.png'
							width={80}
							alt='QR for Broadcast app'
						/>
					</a>
				</div>
				<div className=' sm:hidden shrink-0 rounded-md '>
					<div className=''>
						<a
							href='https://qr.broadcast.events/g/3dWjooOAwS'
							aria-label='Link to broadcast.events'
						>
							<img
								src='https://widget.broadcast.events/v1/musikkfest/icon.png'
								// width={80}
								alt='Broadcast b icon'
								className='rounded-lg w-12 sm:w-20'
							/>
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
