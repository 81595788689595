import React from 'react';

export default function FestivalsVenueTitle({ title }) {
	return (
		<>
			<div
				id='bcfw-heading-venue'
				className='py-8 text-3xl font-bold text-musikkfestLightMagenta'
			>
				{title}
			</div>
		</>
	);
}
