import React from 'react';

export default function FestivalsSettingsButton({
	id,
	onClick,
	ariaLabel,
	children,
}) {
	return (
		<button
			id={id}
			onClick={onClick}
			className=' px-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
			aria-label={ariaLabel}
		>
			{children}
		</button>
	);
}
