import { useEffect, useState } from 'react';

import Axios from 'axios';

import VenueTitle from './VenueTitle';
import LabelVenue from './LabelVenue';
import LabelTime from './LabelTime';
import DetailsModal from './DetailsModal';
import Star from '../atoms/Star';
import InfoModal from './InfoModal';

import { eventLogger } from '../../lib/eventLogger';
import ItemDescription from './ItemDescription';
import ScrollToTop from './ScrollToTop';
import PoweredBy from './PoweredBy';
import FestivalsPoweredBy from './shared/FestivalsPoweredBy';

import moment from 'moment';

import { useFilteredAndSortedItems } from '../../hooks/useFilteredAndSortedItems';
import FestivalsVenueTitle from './shared/FestivalsVenueTitle';
import FestivalsScheduleItem from './shared/FestivalsScheduleItem';
import FestivalsScrollToTop from './shared/FestivalsScrollToTop';
import FestivalsDetailsModal from './shared/FestivalsDetailsModal';
import FestivalsSettingsButton from './shared/FestivalsSettingsButton';
import FestivalsSelectVenue from './shared/FestivalsSelectVenue';
import FestivalsInfoModal from './shared/FestivalsInfoModal';

const CURRENT_PROMOEVENT_NAME = 'Musikkfest';
const CURRENT_PROMOEVENT_ID = 'kSa0JG4F2w';
const SHOW_HEADER = false;
const STICKY_SETTINGS = false;
const SHOW_INFO_MODAL = false;

export default function Festivals({ config }) {
	// console.log(config);
	const [scheduleItems, setScheduleItems] = useState([]);

	const [gridView, setGridView] = useState(false);
	const [selectedVenue, setSelectedVenue] = useState('');
	const [uniqueVenues, setUniqueVenues] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [timeView, setTimeView] = useState(false);

	const [openDetails, setOpenDetails] = useState(false);
	const [openInfo, setOpenInfo] = useState(false);
	const [detailItem, setDetailItem] = useState({});

	const [hideFinishedEvents, setHideFinishedEvents] = useState(false);
	const [sortMethod, setSortMethod] = useState('');

	const hostname = window.location.hostname;

	const filteredAndSortedItems = useFilteredAndSortedItems(scheduleItems, {
		hideFinishedEvents,
		selectedVenue,
		searchText,
		sortMethod,
	});

	// useEffect(() => {
	// 	setScheduleItems(festivals);
	// }, [festivals]);

	useEffect(() => {
		getFestivalSchedule(config.venue);
	}, [config]);

	const getFestivalSchedule = async (venue) => {
		// console.log(venue);
		const res = await Axios.get(
			`https://demo.broadcastapp.no/api/v1/festivals?key=4f68363acfae4b098aec21c8a37d343a&festival=${venue}`
		);

		const body = res.data;
		setScheduleItems(body);
		// console.log(body);
		// setIsLoading(false);
	};

	useEffect(() => {
		extractUniqueVenues();
	}, [scheduleItems]);

	useEffect(() => {
		// const isRead = isWelcomeMessageRead();
		// if (!isRead) {
		// 	setOpenInfo(true);
		// }
		SHOW_INFO_MODAL && displayInfoModal();
	}, []);

	useEffect(() => {
		SHOW_INFO_MODAL && displayInfoModal();
	}, [openInfo]);

	const displayInfoModal = () => {
		const isRead = isWelcomeMessageRead();
		if (!isRead) {
			setOpenInfo(true);
		}
	};

	const handleCloseInfoModal = () => {
		markWelcomeMessageAsRead();
		setOpenInfo(false);
	};

	const extractUniqueVenues = () => {
		const venues = new Set();
		scheduleItems.forEach((item) => {
			const venueName =
				item.externalVenueName || item.relatedEvent.venue.name;
			venues.add(venueName);
		});
		const sortedVenues = Array.from(venues).sort((a, b) =>
			a.localeCompare(b)
		);
		setUniqueVenues(sortedVenues);
	};

	const filterByVenue = (venue) => {
		setSelectedVenue(venue);
		setSearchText('');
	};

	const sortByName = () => {
		setSortMethod('name');
	};

	const sortByTime = () => {
		setSortMethod('time');
	};

	const filterByStarred = () => {
		setSortMethod('starred');
	};

	/**
	 * Sort and then group by stage, with a heading.
	 */
	const sortByStage = () => {
		setSortMethod('stage');
	};

	const searchItems = (search) => {
		setSearchText(search);
		setSelectedVenue('');
	};

	function isWelcomeMessageRead() {
		return JSON.parse(localStorage.getItem('welcomeMessageRead')) || false;
	}

	function markWelcomeMessageAsRead() {
		localStorage.setItem('welcomeMessageRead', JSON.stringify(true));
	}

	const highlightStyle = `
  .highlight {
    background-color: yellow;
    color: black;
  }
`;

	const handleOpenDetailsModal = (e) => {
		eventLogger('festival_view', {
			eventName: e.name,
			eventId: e.objectId,
			venueName: CURRENT_PROMOEVENT_NAME,
			venueId: CURRENT_PROMOEVENT_ID,
			referrer: hostname,
			page: 'PromoDetail',
		});

		setOpenDetails(true);
		setDetailItem(e);
	};

	const handleHideFinishedEvents = (e) => {
		setHideFinishedEvents(e.target.checked);
	};

	// console.log(uniqueVenues);
	return (
		<>
			{/* Powered by */}
			<div
				id='bc-widget-poweredby-header'
				className='border  border-black p-2 mx-0 mt-4 bg-zinc-50/75 mb-8'
			>
				<FestivalsPoweredBy compact={false} />
			</div>

			{/* Header */}
			{SHOW_HEADER && (
				<>
					<div className='flex flex-row items-center justify-between sm:pb-8'>
						<div className='w-20'></div>
						<div className=' flex flex-col items-center justify-center py-4'>
							<h2 className='text-vandalismPrimary text-lg sm:text-2xl font-bold uppercase '>
								Program 2023{' '}
							</h2>
							<p
								className='p-0 text-zinc-400'
								onClick={() => {
									setOpenInfo(true);
								}}
							>
								Need help?
							</p>
						</div>
						<div className=' w-20'></div>
					</div>
				</>
			)}

			<div id='bcfw-wrapper' className=''>
				{/* Settings */}
				<div
					id='bcfw-settings'
					className={`flex flex-col items-center justify-between space-y-2  py-4 lg:flex-row ${
						STICKY_SETTINGS && ' sticky '
					} top-0 `}
				>
					{/* Sorting options */}
					<div
						id='bcfw-settings-sort-wrapper'
						className=' py-2 lg:col-span-4 flex flex-row space-x-4   lg:text-xl '
					>
						<FestivalsSettingsButton
							id='bcfw-settings-sort-time'
							onClick={sortByTime}
							aria-label='Sort by time'
						>
							Time
						</FestivalsSettingsButton>

						<FestivalsSettingsButton
							id='bcfw-settings-sort-stage'
							onClick={sortByStage}
							aria-label='Sort by stage'
						>
							Stage
						</FestivalsSettingsButton>

						<FestivalsSettingsButton
							id='bcfw-settings-sort-name'
							onClick={sortByName}
							aria-label='Sort alphabetically'
						>
							A-Z
						</FestivalsSettingsButton>

						<FestivalsSettingsButton
							id='bcfw-settings-sort-stared'
							onClick={filterByStarred}
							aria-label='Show starred events'
						>
							Starred
						</FestivalsSettingsButton>
					</div>

					{/* Search */}
					<div
						id='bcfw-settings-filter-wrapper'
						className='max-w-full lg:w-auto'
					>
						<label
							htmlFor='bcfw-settings-filter-input'
							className='sr-only'
						>
							Search lineup
						</label>
						<input
							id='bcfw-settings-filter-input'
							type='text'
							placeholder='Search...'
							aria-label='Search lineup'
							value={searchText}
							onChange={(e) => searchItems(e.target.value)}
							className='sm:w-96 p-2 lg:w-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
						/>
					</div>

					{/* Filter by venue */}
					<div
						id='bcfw-settings-venues-wrapper'
						className='flex flex-row items-center '
					>
						{uniqueVenues.length > 1 && (
							<>
								<FestivalsSelectVenue
									selectedVenue={selectedVenue}
									uniqueVenues={uniqueVenues}
									filterByVenue={filterByVenue}
								></FestivalsSelectVenue>
							</>
						)}
					</div>
				</div>

				{/* Toggle past events */}
				<div
					id='bcfw-settings-finished-wrapper'
					className='flex items-center pb-2'
				>
					<input
						type='checkbox'
						// id='showAllEvents'
						checked={hideFinishedEvents}
						onChange={handleHideFinishedEvents}
						// className='form-checkbox h-5 w-5 '
						className='form-checkbox h-5 w-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
						id='bcfw-settings-finished-input'
					/>
					<label
						htmlFor='bcfw-settings-finished-input'
						className='ml-2 text-base'
						id='bcfw-settings-finished-label'
					>
						Hide finished events
					</label>
				</div>

				{/* Venue Title */}
				{selectedVenue && (
					<>
						<FestivalsVenueTitle
							title={selectedVenue}
						></FestivalsVenueTitle>
					</>
				)}

				<div
					id='bcfw-schedule-wrapper'
					className='grid grid-cols-3 gap-2 md:gap-4 p-0'
				>
					{filteredAndSortedItems &&
						filteredAndSortedItems.map((item, index) => (
							<>
								<div
									className='col-span-3 sm:col-span-3  py-0' // Added vertical padding here
									key={index}
									id='bcfw-schedule-row'
								>
									{item.isTitleRow ? (
										<div
											className='text-3xl font-bold'
											id='bcfw-schedule-row-title'
										>
											{item.venueName && (
												<FestivalsVenueTitle
													title={item.venueName}
												/>
											)}
											{item.date && item.date}
										</div>
									) : (
										<div id='bcfw-schedule-row-item'>
											<FestivalsScheduleItem
												item={item}
												handleOpenDetailsModal={
													handleOpenDetailsModal
												}
												highlightStyle={highlightStyle}
												config={config}
											/>
										</div>
									)}
								</div>
								{/* Add a config option to make this accessible via the vidget */}
								{!config.hideDivider && (
									<>
										{index <
											filteredAndSortedItems.length -
												1 && (
											<div className='bcfw-schedule-divider col-span-3 border-b' /> // Horizontal divider
										)}
									</>
								)}
							</>
						))}
				</div>

				<div
					className='border border-black p-2 mt-8 bg-zinc-50 mb-16'
					id='bcfw-poweredby-footer'
				>
					<FestivalsPoweredBy compact={false} />
				</div>

				<div className=''>
					<FestivalsScrollToTop></FestivalsScrollToTop>
				</div>

				{/* <DetailsModal
					open={openDetails}
					setOpen={setOpenDetails}
					itemToShow={detailItem}
				></DetailsModal> */}

				<FestivalsDetailsModal
					open={openDetails}
					setOpen={setOpenDetails}
					itemToShow={detailItem}
				></FestivalsDetailsModal>

				<FestivalsInfoModal
					open={openInfo}
					handleCloseInfoModal={handleCloseInfoModal}
				></FestivalsInfoModal>
			</div>
		</>
	);
}
