import { useMemo } from 'react';
import moment from 'moment';

export function useFilteredAndSortedItems(
	items,
	{ hideFinishedEvents, selectedVenue, searchText, sortMethod }
) {
	return useMemo(() => {
		let filteredItems = [...items];

		// Filter out finished events if hideFinishedEvents is true
		if (hideFinishedEvents) {
			const now = moment().unix();
			filteredItems = filteredItems.filter((item) => item.end_time > now);
		}

		// Filter by venue if a venue is selected
		if (selectedVenue !== '') {
			filteredItems = filteredItems.filter((item) => {
				const venueName =
					item.externalVenueName || item.relatedEvent.venue.name;
				return venueName === selectedVenue;
			});
		}

		// Filter by search text if a search text is inputted
		if (searchText !== '') {
			const searchRegex = new RegExp(searchText, 'gi');
			filteredItems = filteredItems.filter((item) => {
				const nameMatch = searchRegex.test(item.name);
				const tagsMatch =
					item.tags && item.tags.some((tag) => searchRegex.test(tag));
				const externalVenueNameMatch =
					item.externalVenueName &&
					searchRegex.test(item.externalVenueName);
				const relatedEventVenueNameMatch =
					item.relatedEvent?.venue?.name &&
					searchRegex.test(item.relatedEvent.venue.name);
				return (
					nameMatch ||
					tagsMatch ||
					externalVenueNameMatch ||
					relatedEventVenueNameMatch
				);
			});
		}

		// Sorting
		switch (sortMethod) {
			case 'name':
				filteredItems.sort((a, b) =>
					a.name.localeCompare(b.name, 'nb', { sensitivity: 'base' })
				);
				break;
			case 'time':
				filteredItems.sort((a, b) => a.start_time - b.start_time);

				const groupedItemsByTime = [];
				let currentDate = null;

				filteredItems.forEach((item) => {
					const itemDate = new Date(
						item.start_time_iso
					).toLocaleDateString();

					if (itemDate !== currentDate) {
						groupedItemsByTime.push({
							isTitleRow: true,
							date: itemDate,
						});
						currentDate = itemDate;
					}

					groupedItemsByTime.push(item);
				});

				filteredItems = groupedItemsByTime;
				break;
			case 'stage':
				const sortedItems = [...filteredItems].sort((a, b) => {
					const aVenue =
						a.externalVenueName || a.relatedEvent.venue.name;
					const bVenue =
						b.externalVenueName || b.relatedEvent.venue.name;

					if (aVenue !== bVenue) {
						return aVenue.localeCompare(bVenue);
					}

					return a.start_time - b.start_time;
				});

				const groupedItemsByStage = [];
				let currentVenue = null;

				sortedItems.forEach((item) => {
					const venueName =
						item.externalVenueName || item.relatedEvent.venue.name;

					if (venueName !== currentVenue) {
						groupedItemsByStage.push({
							isTitleRow: true,
							venueName,
						});
						currentVenue = venueName;
					}

					groupedItemsByStage.push(item);
				});

				filteredItems = groupedItemsByStage;
				break;
			case 'starred':
				const favoriteEvents =
					JSON.parse(localStorage.getItem('favoriteEvents')) || [];
				const isStarred = (item) =>
					favoriteEvents.includes(item.objectId);
				filteredItems = filteredItems.filter(isStarred);
				break;
			default:
				break;
		}

		return filteredItems;
	}, [items, hideFinishedEvents, selectedVenue, searchText, sortMethod]);
}
