import { useState, useEffect } from 'react';

const IconStar = ({ className }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			fill='currentColor'
			className={className}
		>
			<path
				fillRule='evenodd'
				d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
				clipRule='evenodd'
			/>
		</svg>
	);
};

const IconStarOutline = ({ className }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
			strokeWidth='1.0'
			stroke='currentColor'
			className={className}
		>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
			/>
		</svg>
	);
};

export default function FestivalsStar({ event, disabled }) {
	const [isFavorite, setIsFavorite] = useState(false);

	useEffect(() => {
		const favoriteEvents =
			JSON.parse(localStorage.getItem('favoriteEvents')) || [];
		setIsFavorite(favoriteEvents.includes(event.objectId));
	}, [event.objectId]);

	function handleStarClick() {
		if (disabled) return;
		toggleFavorite(event.objectId);
		setIsFavorite(!isFavorite);
	}
	function saveFavoriteEvent(eventId) {
		const favoriteEvents =
			JSON.parse(localStorage.getItem('favoriteEvents')) || [];
		if (!favoriteEvents.includes(eventId)) {
			favoriteEvents.push(eventId);
			localStorage.setItem(
				'favoriteEvents',
				JSON.stringify(favoriteEvents)
			);
		}
	}

	function removeFavoriteEvent(eventId) {
		const favoriteEvents =
			JSON.parse(localStorage.getItem('favoriteEvents')) || [];
		const index = favoriteEvents.indexOf(eventId);
		if (index > -1) {
			favoriteEvents.splice(index, 1);
			localStorage.setItem(
				'favoriteEvents',
				JSON.stringify(favoriteEvents)
			);
		}
	}

	function toggleFavorite(eventId) {
		const favoriteEvents =
			JSON.parse(localStorage.getItem('favoriteEvents')) || [];
		if (favoriteEvents.includes(eventId)) {
			removeFavoriteEvent(eventId);
		} else {
			saveFavoriteEvent(eventId);
		}
	}

	return (
		<>
			<button onClick={handleStarClick} aria-label='Star this event'>
				{isFavorite ? (
					<IconStar className='h-6 w-6 md:h-8 sm:w-8 text-musikkfestLightMagenta'></IconStar>
				) : (
					<IconStarOutline className='h-6 w-6 md:h-8 sm:w-8 text-musikkfestLightMagenta'></IconStarOutline>
				)}{' '}
			</button>
		</>
	);
}
